<template>
	<div class="c-people-listing">
		<!-- FILTER -->
		<div class="c-people-filter">
			<div class="c-people-filter__container">
				<button class="c-people-filter__expander-button-open c-expand-collapse__trigger js-expand-collapse__open" aria-controls="people-filter" aria-expanded="false">
					Open filter
					<span class="iconf-arrow-down-small"></span>
				</button>
				<fieldset
					class="c-people-filter__wrapper c-form c-expand-collapse js-expand-collapse allow-animations"
					aria-hidden="true"
					data-expand-collapse-use-hidden="true"
					id="people-filter" aria-live="polite"
					data-expand-collapse-animation="slideDown">
					<div class="c-people-filter__option">
						<label for="name">Name</label>
						<input id="name" placeholder="Type name" list="people-names" v-model="filterState.name" type="search" @change="apply()" @keydown.enter="onPressEnter" />
						<span class="iconf-search c-people-filter__option-search-icon"></span>
						<datalist id="people-names">
							<option v-for="person in data.Items" :value="person.Name"></option>
						</datalist>
					</div>
					<div class="c-people-filter__option">
						<label for="role">Role</label>
						<select id="role" v-model="filterState.role" @change="apply()">
							<option value="0">All</option>
							<option v-for="filter in data.Filters.Roles" :value="filter.Key">{{filter.Value}}</option>
						</select>
					</div>
					<div class="c-people-filter__option">
						<label for="expertise">Expertise</label>
						<select id="expertise" v-model="filterState.expertise" @change="apply()">
							<option value="0">All</option>
							<option v-for="filter in data.Filters.Expertise" :value="filter.Key">{{filter.Value}}</option>
						</select>
					</div>
					<div class="c-people-filter__option">
						<label for="locations">Locations</label>
						<select id="locations" v-model="filterState.locations" @change="apply()">
							<option value="0">All</option>
							<option v-for="filter in data.Filters.Locations" :value="filter.Key">{{filter.Value}}</option>
						</select>
					</div>
					<!--<button class="c-people-filter__button" @click="apply()">Apply</button>-->
				</fieldset>
				<button class="c-people-filter__expander-button-close js-expand-collapse__close" aria-controls="people-filter" aria-expanded="false">
					Close filter
					<span class="iconf-arrow-up-small"></span>
				</button>
			</div>
		</div>

		<!-- SUMMARY -->
		<div class="c-filter-summary">
			<transition-group name="filters">
				<div class="c-filter-summary__filter-summary" v-if="currentSelections.length">
					<div class="c-filter-summary__heading">Filtered by:</div>
					<button class="c-filter-summary__filter-buttons" v-for="selection in currentSelections" @click="setState(selection.state)">
						{{selection.label}}
						<span class="iconf-close"></span>
					</button>
					<button class="c-filter-summary__reset" @click="resetState()">
						<span class="iconf-reset"></span>
						Reset
					</button>
				</div>
			</transition-group>
			<div class="c-filter-summary__count">Showing {{filteredItems.length}} results.</div>
		</div>

		<!-- LIST -->
		<div class="c-people-list">
			<transition-group name="list">
				<people-tile v-bind:person="person" v-for="person in filteredItems" :key="person.Key" />
			</transition-group>
			<!-- TODO: Check if want real time updates from filter -->
		</div>
	</div>

</template>
<script>
	export default {
		name: 'people-listing',
		props: ['data'],
		data() {
			return {
				filterState: {
					name: null, // text - keyword
					role: 0, // int - id
					expertise: 0, // int - id
					locations: 0, // int - id
				},
				filteredItems: [], // object - reduced set of people items used for render
				currentSelections: [] // object - current filters in use can be removed individualy
			}
		},
		mounted() {
			this.filteredItems = this.data.Items;

			if (window.location.search) {

				function parseQuery(queryString) {
					var query = {};
					var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
					for (var i = 0; i < pairs.length; i++) {
						var pair = pairs[i].split('=');
						query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
					}
					return query;
				}

				function applyFilterFromQuery(queryKey, filters) {
					if (!!queryData[queryKey]) {
						var match = Array.from(filters[queryKey]).find(x => x.Value === queryData[queryKey] || x.Key === parseInt(queryData[queryKey]));
						if (match) {
							return match.Key;
						}
					}
					return 0;
				}

				var queryData = parseQuery(window.location.search);

				this.filterState.role = applyFilterFromQuery('Roles', this.data.Filters);
				this.filterState.expertise = applyFilterFromQuery('Expertise', this.data.Filters);
				this.filterState.locations = applyFilterFromQuery('Locations', this.data.Filters);
				this.apply();
				// console.log(queryData, this.data.Filters);
			}
		},
		methods: {
			apply() {
				this.filteredItems = this.filterItems(this.data.Items);
			},
			filterItems(items) {
				this.currentSelections = [];

				if (this.filterState.name?.length >= 3) {
					items = items.filter(x => x.Name.toLowerCase().includes(this.filterState.name.toLowerCase()) === true);

					this.currentSelections.push({
						label: `'${this.filterState.name}'`,
						state: {
							name: null,
							role: this.filterState.role,
							expertise: this.filterState.expertise,
							locations: this.filterState.locations,
						}
					});
				}
				if (this.filterState.role > 0) {
					items = items.filter(x => x.Categories.indexOf(this.filterState.role) >= 0);

					let current = this.data.Filters.Roles.find(x => x.Key === this.filterState.role);
					this.currentSelections.push({
						label: current?.Value,
						state: {
							name: this.filterState.name,
							role: 0,
							expertise: this.filterState.expertise,
							locations: this.filterState.locations,
						}
					});
				}
				if (this.filterState.expertise > 0) {
					items = items.filter(x => x.Categories.indexOf(this.filterState.expertise) >= 0);

					let current = this.data.Filters.Expertise.find(x => x.Key === this.filterState.expertise);
					this.currentSelections.push({
						label: current?.Value,
						state: {
							name: this.filterState.name,
							role: this.filterState.role,
							expertise: 0,
							locations: this.filterState.locations,
						}
					});
				}
				if (this.filterState.locations > 0) {
					items = items.filter(x => x.Categories.indexOf(this.filterState.locations) >= 0);

					let current = this.data.Filters.Locations.find(x => x.Key === this.filterState.locations);
					this.currentSelections.push({
						label: current?.Value,
						state: {
							name: this.filterState.name,
							role: this.filterState.role,
							expertise: this.filterState.expertise,
							locations: 0,
						}
					});
				}
				return items;
			},
			setState(state) {
				this.filterState = state;
				this.apply();
			},
			resetState() {
				this.filterState = {
					name: null,
					role: 0,
					expertise: 0,
					locations: 0,
				};
				this.apply();
			},
			onPressEnter: function (e) {
				e.target.blur();
			}
		}
	};
</script>
